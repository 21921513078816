import axios from 'axios';
import { useCallback, useMemo } from 'react';
import useSWR, { mutate } from 'swr';
import { DOC_MODEL } from '../constants/api';
import { ROLES_RAW } from '../constants/permissions';
import { keyBy } from '../utils';
import { formatData } from '../utils/formatter';
import { useApp } from '../utils/hooks';
import { create as createStore } from 'zustand';

const opts = {
  revalidate: false
};

export const useSignature = createStore((set) => ({
  selectedSignatures: [],
  setSelectedSignatures(selectedSignatures) {
    set({ selectedSignatures });
  }
}));

export const fetcher = async (
  documentType,
  filter,
  { showAllCompanies } = {}
) => {
  documentType = getType(documentType);
  return await axios
    .get(`/${documentType}`, {
      params: {
        filter,
        showAll: showAllCompanies
      }
    })
    .then(({ data }) => {
      let dataSource = data;
      if ('data' in data && Array.isArray(data.data)) {
        dataSource = data.data;
      }
      return formatData({ dataSource, documentType });
    })
    .catch(() => null);
};

export const useFetch = (documentType, filter) => {
  documentType = getType(documentType);
  return useSWR(documentType, () => fetcher(documentType, filter), {
    revalidateOnFocus: false,
    revalidateOnMount: true
  });
};

const fetchById = async (documentType, key) => {
  documentType = getType(documentType);
  const resource = await axios
    .get(`/${documentType}/${key}`)
    .then(({ data }) => data)
    .catch(() => null);

  if (resource) {
    await mutate(
      documentType,
      async (prevData = []) => {
        const index = prevData.findIndex((x) => x._id === key);

        if (index > -1) {
          prevData[index] = resource;
          return [...prevData];
        }

        return [...prevData, resource];
      },
      opts
    );
  }

  return resource;
};

const fetchBy = async (documentType, param) => {
  documentType = getType(documentType);
  const resource = await axios
    .get(`/${documentType}/${param}`)
    .then(({ data }) => formatData({ dataSource: data, documentType }))
    .catch(() => null);

  if (resource) {
    await mutate(
      documentType,
      async (prevData = []) => {
        const oldData = keyBy(prevData, '_id');
        const newData = keyBy(resource, '_id');

        return Object.values({ ...oldData, ...newData });
      },
      opts
    );
  }

  return resource;
};

const create = async (documentType, data, apiCall = true) => {
  documentType = getType(documentType);
  let resource = data;

  if (apiCall) {
    resource = await axios
      .post(`/${documentType}`, data)
      .then(({ data }) => formatData({ dataSource: data, documentType }))
      .catch(() => null);
  }

  if (resource || apiCall === false) {
    await mutate(
      documentType,
      async (prevData = []) => [
        ...prevData,
        ...(Array.isArray(resource) ? resource : [resource])
      ],
      opts
    );
  }

  return resource;
};

const add = async (documentType, data) => {
  documentType = getType(documentType);
  let resource = data;

  await mutate(
    documentType,
    async (prevData = []) => [
      ...prevData,
      ...(Array.isArray(data) ? data : [data])
    ],
    opts
  );

  return resource;
};

const set = async (documentType, data) => {
  documentType = getType(documentType);
  await mutate(documentType, async () => data, opts);

  return true;
};

const update = async (documentType, newData, apiCall = true) => {
  documentType = getType(documentType);
  if (apiCall) {
    newData = await axios
      .put(`/${documentType}`, newData)
      .then(({ data }) => formatData({ dataSource: data, documentType }))
      .catch(() => null);
  }

  await mutate(
    documentType,
    async (prevData = []) => {
      if (!newData) return prevData;

      return { ...prevData, ...newData };
    },
    opts
  );
  return newData;
};

const updateMany = async (documentType, files) => {
  documentType = getType(documentType);
  await mutate(
    documentType,
    async (prevData = []) => {
      const data = keyBy(prevData, '_id');
      const newData = keyBy(files, '_id');
      return Object.values({ ...data, ...newData });
    },
    opts
  );
  return true;
};

const updateById = async (documentType, newData, apiCall = true) => {
  documentType = getType(documentType);
  let resource = newData;

  if (apiCall) {
    resource = await axios
      .put(`/${documentType}/${newData?._id}`, newData)
      .then(({ data }) => formatData({ dataSource: data, documentType }))
      .catch(() => null);
  }

  if (resource || apiCall === false) {
    await mutate(
      documentType,
      async (prevData = []) => {
        const index = prevData.findIndex((x) => x._id === resource?._id);

        if (index > -1) {
          prevData[index] = resource;
          return [...prevData];
        }

        return [...prevData, resource];
      },
      opts
    );
  }

  return resource;
};

const deleteMany = async (documentType, keys = [], apiCall = true) => {
  documentType = getType(documentType);
  let isDeleted = apiCall === false;

  if (apiCall) {
    isDeleted = await axios
      .delete(`/${documentType}`, { data: keys })
      .then(() => true)
      .catch(() => false);
  }

  if (isDeleted) {
    await mutate(
      documentType,
      async (prevData = []) => {
        return [...prevData.filter((x) => !keys.includes(x._id))];
      },
      opts
    );
  }

  return isDeleted;
};

const fetchAll = async (docType, { filter, showAllCompanies } = {}) => {
  const resource = await fetcher(docType, filter, { showAllCompanies });

  if (resource) {
    await mutate(docType, async () => resource, opts);
  }

  return [];
};

export const useAPI = () =>
  useMemo(
    () => ({
      fetchAll,
      fetchById,
      fetchBy,
      set,
      add,
      create,
      update,
      updateMany,
      updateById,
      deleteMany
    }),
    []
  );

export const useDataSource = (docType, filterPerCompany) => {
  const { selectedCompany } = useApp();
  const data = useData(docType);

  const list = useMemo(
    () =>
      Object.values(data ?? {}).filter((x) => {
        return (
          !filterPerCompany || !selectedCompany || x.company === selectedCompany
        );
      }),
    [data, selectedCompany, filterPerCompany]
  );

  const get = useCallback(
    (key) => {
      return list.find((x) => x._id === key);
    },
    [list]
  );

  return useMemo(
    () => ({
      list,
      get
    }),
    [list, get]
  );
};

export const useData = (docType) => {
  const { data } = useSWR(docType);

  return data;
};

export const useUserCompanies = () => {
  const profile = useData(DOC_MODEL.PROFILE);
  const { showAllCompanies } = useApp();
  const list = useDataSource(DOC_MODEL.COMPANY).list;

  return list.filter(
    (x) => showAllCompanies || profile?.companies?.includes(x._id)
  );
};

export const useBusiness = () => {
  const list = useDataSource(DOC_MODEL.BUSINESS).list;

  return list.filter((x) => x.isActive);
};

export const useMastersPerCompany = () => {
  const data = useData(DOC_MODEL.ESTABLISHMENT_INFO);

  return data?.mastersPerCompany ?? false;
};

export const useUserRole = () => {
  const { role } = useData(DOC_MODEL.PROFILE);
  return ROLES_RAW.includes(role) ? role : null;
};

const getType = (documentType) => {
  documentType = documentType.split(':').pop();
  return (
    {
      [DOC_MODEL.BUSINESS_UNIT]: DOC_MODEL.COMPANY
    }[documentType] ?? documentType
  );
};

export const useCompanies = () => {
  const companyInfos = useDataSource(DOC_MODEL.COMPANY_INFO).list;
  return useDataSource(DOC_MODEL.COMPANY)
    .list.filter((x) => !x.masterCompany)
    .map((company) => {
      const companyInfo = companyInfos.find((x) => x.company === company._id);
      return { ...company, companyInfo };
    });
};
