import { createContext, lazy, Suspense, useEffect, useState } from 'react';
import { Loading } from './layout/utils/AppLoading';
import { useIsAppInactive } from './utils/hooks';
import { Maintenance } from './pages/Maintenance';
import themes from 'devextreme/ui/themes';
import { UpdatePopup } from './layout/UpdatePopup';
import { loadLocale } from './locale/loader';
import { useIsMobileListener } from './utils';
import axios from 'axios';
import { memo } from 'react';
import { useMemo } from 'react';

const storedTheme = localStorage.getItem('dx-theme') || 'generic.light';

const currentTheme = ['generic.light', 'generic.light.compact'].includes(
  storedTheme
)
  ? storedTheme
  : 'generic.light';

themes.current(currentTheme);

const MainContainer = lazy(() => import('./containers/MainContainer'));
const AuthContainer = lazy(() => import('./containers/AuthContainer'));

export const AppContext = createContext({
  isMobile: false,
  appMenu: true,
  lang: 'es',
  isDataLoaded: false,
  showAllCompanies: false,
  currentChat: undefined,
  setShowAllCompanies: true,
  setDataLoaded: undefined,
  setAppMenuOpened: undefined,
  setCurrentChat: undefined,
  selectedCompany: null,
  setSelectedCompany: undefined
});

const App = () => {
  const isAppInactive = useIsAppInactive();
  const [lang, setLang] = useState(localStorage.getItem('lang') || 'es');
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [appMenu, setAppMenuOpened] = useState(true);
  const [currentChat, setCurrentChat] = useState(null);
  const [showAllCompanies, setShowAllCompanies] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState();
  const token = axios.defaults.headers.Authorization;

  const isMobile = useIsMobileListener();

  useEffect(() => {
    loadLocale(lang);
  }, [lang]);

  useEffect(() => {
    if (isDataLoaded && !token) {
      setDataLoaded(false);
    }
  }, [isDataLoaded, token]);

  const appValues = useMemo(
    () => ({
      isMobile,
      isDataLoaded,
      setDataLoaded,
      lang,
      setLang,
      appMenu,
      setAppMenuOpened,
      showAllCompanies,
      setShowAllCompanies,
      currentChat,
      setCurrentChat,
      selectedCompany,
      setSelectedCompany
    }),
    [
      isMobile,
      lang,
      appMenu,
      showAllCompanies,
      currentChat,
      isDataLoaded,
      selectedCompany,
      setSelectedCompany
    ]
  );

  if (isAppInactive) return <Maintenance />;

  return (
    <AppContext.Provider value={appValues}>
      {token && isDataLoaded ? (
        <Suspense fallback={<Loading />}>
          <UpdatePopup />
          <MainContainer />
        </Suspense>
      ) : (
        <Suspense fallback={<Loading />}>
          <AuthContainer />
        </Suspense>
      )}
    </AppContext.Provider>
  );
};

export default memo(App, () => true);
