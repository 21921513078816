import { locale, loadMessages } from 'devextreme/localization';
import devEs from 'devextreme/localization/messages/es.json';
import devEn from 'devextreme/localization/messages/en.json';

import es from './es.json';
import en from './en.json';

const qwarkLangs = { es, en };

const devextremeLangs = { en: devEn, es: devEs };

let messages = es;

export const loadLocale = (newLang = 'es') => {
  newLang = newLang.toLowerCase().trim();

  if (!qwarkLangs[newLang]) {
    newLang = 'es';
  }

  messages = qwarkLangs[newLang] ?? es;

  // Devextreme
  locale(newLang);
  loadMessages(devextremeLangs[newLang]);

  localStorage.setItem('lang', newLang);
};

export const msg = (key) => {
  return messages[key] ?? '¡¡¡TODO!!!';
};
