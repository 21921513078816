import { useRef, useState } from 'react';
import { Tooltip } from 'devextreme-react/tooltip';
import styled from 'styled-components';

export const CustomTooltip = ({
  title,
  children = <i className="dx-icon-warning" style={{ color: '#EFC506' }} />
}) => {
  const ref = useRef();
  const [visible, setVisible] = useState(false);

  const toggle = (value) => () => setVisible(value);

  return (
    <>
      <div
        style={{ textAlign: 'center' }}
        ref={ref}
        onMouseEnter={toggle(true)}
        onMouseLeave={toggle(false)}
      >
        {children}
      </div>
      <Tooltip target={ref.current} visible={visible} position={'top'}>
        {title}
      </Tooltip>
    </>
  );
};

export const CustomTag = ({ icon, size, title, children, color, style }) => {
  return (
    <TagWrapper color={color} size={size} style={style} title={title}>
      {icon}
      {title ?? children ? (
        <span className="text">{title ?? children}</span>
      ) : null}
    </TagWrapper>
  );
};

const TagWrapper = styled.span`
  ${({ color }) => {
    const c = {
      green: {
        background: '#f6ffed',
        text: '#389e0d'
      },
      volcano: {
        background: '#fff2e8',
        text: '#d4380d'
      },
      red: {
        background: '#fff1f0',
        text: '#cf1322'
      },
      blue: {
        background: '#e6f7ff',
        text: '#096dd9'
      },
      gold: {
        background: '#fffbe6',
        text: '#d48806'
      },
      magenta: {
        background: '#fff0f6',
        text: '#c41d7f'
      },
      geekblue: {
        background: '#f0f5ff',
        text: '#1d39c4'
      },
      purple: {
        background: '#f9f0ff',
        text: '#531dab'
      }
    }[color];

    return `background: ${c?.background ?? '#f1f1f1'}; color: ${
      c?.text ?? 'black'
    };`;
  }}
  ${({ size = 'normal' }) => {
    const c =
      {
        small: '12px',
        normal: 'inherit',
        large: '24px'
      }[size] ?? size;

    return `font-size: ${c}; ion-icon { font-size: ${c}}`;
  }}
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  border-radius: 16px;
  display: flex;
  gap: 6px;
  overflow: hidden;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
