import styled from 'styled-components';

export const Maintenance = () => {
  return (
    <Wrapper>
      <h1>Estamos en mantenimiento</h1>
      <h2>
        Volveremos a activar nuestros servicios en la mayor brevedad posible
      </h2>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 64px;
  text-align: center;
`;
