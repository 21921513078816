import App from './App';
import axios from 'axios';
import dayjs from 'dayjs';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { createRoot } from 'react-dom/client';
import { SocketProvider } from './utils/Socket';
import { BrowserRouter } from 'react-router-dom';
import { BASE_URL } from './constants/api';

import './styles/index.css';
import './assets/fonts/index.css';
import 'dayjs/locale/es';

const root = document.getElementById('root');
createRoot(root).render(
  // <React.StrictMode>
  <BrowserRouter>
    <SocketProvider>
      <App />
    </SocketProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

serviceWorkerRegistration.register();

axios.defaults.baseURL = BASE_URL;
dayjs.locale('es');
