import { Plugins } from '@capacitor/core';
import { useEffect, useState } from 'react';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { useContext } from 'react';
import { AppContext } from '../App';
import { v4 } from 'uuid';

const { Storage } = Plugins;

const readFileAsDataURL = async (file) => {
  let result_base64 = await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });

  return result_base64;
};

export const formatDocuments = async (acceptedFiles) => {
  return await Promise.all(
    acceptedFiles.map(async (acceptedFile) => {
      const dataURL = await readFileAsDataURL(acceptedFile);

      return {
        document: dataURL.split('base64,')[1],
        fileName: acceptedFile.name ?? `${v4()}.pdf`
      };
    })
  );
};

export const useIsMobile = () => useContext(AppContext).isMobile;

export const useIsMobileListener = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleWindowSizeChange = () => setIsMobile(window.innerWidth <= 768);
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  return isMobile;
};

// RESIZE
export const PLATFORM = {
  IOS: 'iOS',
  ANDROID: 'Android',
  WINDOWSPHONE: 'Windows Phone'
};

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return PLATFORM.WINDOWSPHONE;
  }

  if (/android/i.test(userAgent)) {
    return PLATFORM.ANDROID;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return PLATFORM.IOS;
  }

  return 'unknown';
};

// COOKIES
export const setCookie = async (key, value, exdays) => {
  await Storage.set({ key, value: JSON.stringify(value) });
};

export const getCookie = async (key) => {
  const c = await Storage.get({ key });
  return JSON.parse(c.value);
};

export const deleteCookie = async (key) => {
  await Storage.remove({ key });
};

export const notification = (message, type = 'info', duration = 10) => {
  notify(
    {
      message,
      width: 'auto',
      animation: {
        show: { type: 'fade', duration: 400, from: 0, to: 1 },
        hide: { type: 'fade', duration: 400, from: 1, to: 0 }
      },
      closeOnClick: true,
      position: { at: 'center top', offset: { x: 0, y: 100 } },
      type,
      displayTime: duration * 1000
    },
    {
      position: 'top center',
      direction: 'down-stack'
    }
  );
};

export async function urlContentToDataUri(url) {
  const response = await fetch(url);
  const blob = await response.blob();
  return await new Promise((callback) => {
    const reader = new FileReader();
    reader.onload = function () {
      callback(this.result);
    };
    reader.readAsDataURL(blob);
  });
}

export const downloadLink = (url, fileName) => {
  return () => {
    axios({
      url,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      const href = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    });
  };
};

export const getNextAccount = ({
  providers,
  clients,
  serie = '4000',
  company
}) => {
  const lastAccount =
    (providers ?? clients ?? [])
      .filter(
        (x) => x.company === company && x.accountingAccount?.startsWith(serie)
      )
      .map((x) => x.accountingAccount)
      .sort((a, b) => a - b)
      .pop() ?? serie.padEnd(8, '0');

  return (Number(lastAccount) + 1).toString();
};

export const sumProperties = (dataSource = [], prop) => {
  return dataSource.reduce((prev, next) => {
    const value = next[prop] ?? 0;
    const newValue = isNaN(value) ? 0 : Math.round(value * 100) / 100;

    return (prev += newValue);
  }, 0);
};

export const keyBy = (data = [], key) => {
  const result = {};
  if (Array.isArray(data)) {
    data.forEach((x) => {
      result[x[key]] = x;
    });
  } else {
    result[data[key]] = data;
  }

  return result;
};

export const isCompact = () => {
  return localStorage.getItem('dx-theme') === 'generic.light.compact';
};
