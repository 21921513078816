import { useContext, useState, useEffect, createContext } from 'react';
import io from 'socket.io-client';
import { BASE_URL, DOC_MODEL } from '../constants/api';
import { useData } from '../store';

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState();
  const profile = useData(DOC_MODEL.PROFILE);
  const establishmentId = profile?.establishment;

  useEffect(() => {
    if (establishmentId && profile?.id) {
      const url = `${BASE_URL}${establishmentId}`;
      const socket = io(url);
      setSocket(socket);
      console.log(`Listening to: ${url}`);
      socket.emit('conectado', profile.id);
    }
  }, [establishmentId, profile?.id]);

  useEffect(() => {
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [socket]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
