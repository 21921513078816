import axios from 'axios';
import { Popup } from 'devextreme-react';
import { useEffect } from 'react';
import { useState } from 'react';
import { CustomTag } from '../components';
import { msg } from '../locale/loader';

export const UpdatePopup = () => {
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    if (!data) {
      axios
        .get('/changelog.json', { baseURL: null, signal: controller.signal })
        .then(({ data }) => {
          const updateDate = localStorage.getItem('updateDate');
          const result = Object.entries(data).pop();

          setData([...result]);
          setOpen(updateDate !== result[0]);
        })
        .catch(() => null);
    }

    return () => {
      controller.abort();
    };
  }, [data]);

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem('updateDate', data?.[0]);
  };

  return (
    <Popup
      visible={open}
      title={`Actualzación ${data?.[0]}`}
      height={'auto'}
      maxWidth={600}
      onHiding={handleClose}
      maxHeight="100%"
      toolbarItems={[
        {
          widget: 'dxButton',
          location: 'after',
          toolbar: 'bottom',
          options: {
            text: msg('ACCEPT'),
            icon: 'check',
            type: 'default',
            onClick: handleClose
          }
        }
      ]}
    >
      <section style={{ overflow: 'hidden auto', height: '100%' }}>
        <p>
          <b>Recuerda:</b>
          <br />
          Para reportar cualquier error, envía un correo detallado a
          support@kuarasoftware.com
        </p>
        {Object.entries(data?.[1] ?? {}).map(([title, articles]) => {
          return (
            <article key={title}>
              <CustomTag
                color="magenta"
                size="18px"
                style={{ padding: '2px 8px', marginBottom: '8px' }}
              >
                {title}
              </CustomTag>

              <ul>
                {articles.map((x, index) => (
                  <li key={index}>{x}</li>
                ))}
              </ul>
              <br />
            </article>
          );
        })}
      </section>
    </Popup>
  );
};
