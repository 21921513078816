import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { notification, useIsMobile } from '.';
import { DOC_MODEL } from '../constants/api';
import { useSocket } from './Socket';
import { useAPI, useData, useDataSource } from '../store';
import { useContext } from 'react';
import { AppContext } from '../App';

const MOBILE_PATHS = ['/home', '/signature', '/chat', '/profile', '/login'];

export const useCheckMobileAccess = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const pathname = useGetCurrentPage();

  useEffect(() => {
    const isNotValidMobilePath = isMobile && !MOBILE_PATHS.includes(pathname);
    if (isNotValidMobilePath) {
      navigate('/home');
    }
  });
};

export const useRedirectInitialPage = (isDataLoaded) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [initialRoute, setInitialRoute] = useState(pathname);

  useEffect(() => {
    if (isDataLoaded && initialRoute) {
      setInitialRoute(null);
    }
  }, [navigate, isDataLoaded, initialRoute]);
};

export const useMainSocketListeners = () => {
  const users = useDataSource(DOC_MODEL.USER);
  const profile = useData(DOC_MODEL.PROFILE);
  const { currentChat } = useApp();
  const api = useAPI();
  const socket = useSocket();

  useEffect(() => {
    if (profile && socket) {
      socket.on('error', (message) => {
        notification(message, 'error', 5);
      });
      socket.on(
        'processedManyDocuments',
        async ({ data, documentType, hasFailed }) => {
          if (hasFailed) {
            notification(data?.errorMessage, 'error', 3);
            return;
          }

          const documentToStore = data.pop();

          sessionStorage.setItem(
            'LAST_PROCESSED_PDF_SPLITTER',
            JSON.stringify({
              storedDocument: documentToStore,
              documentType
            })
          );

          if (!documentToStore) return;

          if (!documentToStore.accounted) {
            await api.updateById(
              documentType.toLowerCase(),
              documentToStore,
              false
            );
          }

          notification('Documento procesado', 'success', 3);
        }
      );

      socket.on('recurrentInvoice', async ({ data: recurrentInvoice }) => {
        await api.updateById(
          DOC_MODEL.RECURRENT_BILLS,
          recurrentInvoice,
          false
        );
      });

      socket.on('signatures', async (response) => {
        await api.fetchAll(DOC_MODEL.SIGNATURE);

        const { type, documentNumber, action } = response;

        const docModel = type?.toLowerCase() ?? null;

        if (docModel) {
          if (action === 'delete') {
            await api.deleteMany(docModel, documentNumber, false);
          } else {
            // await api.fetchById(docModel, documentNumber);
          }
        }
      });
    }
    if (profile && socket) {
      socket.on('receive-message', (message) => {
        const { sender, conversationId } = message;
        const user = users.get(sender);
        if (
          user &&
          user.value !== profile.id &&
          (!currentChat || currentChat._id !== conversationId)
        ) {
          notification(`Has recibido un mensaje de ${user.label}`, 'info');
        }

        axios
          .get(`/${DOC_MODEL.MESSAGE}/${conversationId}`)
          .then(({ data }) => api.updateMany(DOC_MODEL.MESSAGE, data.messages))
          .catch(() => null);
      });
    }

    return () => {
      if (socket) {
        socket.off('error');
        socket.off('receive-message');
        socket.off('signatures');
        socket.off('processedManyDocuments');
      }
    };
  }, [users, profile, currentChat, socket, api]);
};

export const useIsAppInactive = () => {
  const [isInactive, setInactive] = useState(false);
  const socket = useSocket();

  useEffect(() => {
    socket?.on('maintenance', async ({ isActive }) => {
      setInactive(isActive);
    });
    axios.get('/').then(({ data = {} } = {}) => {
      const { maintenance = false } = data;

      if (isInactive !== maintenance) {
        setInactive(maintenance);
      }
    });

    return () => socket?.off('maintenance');
  }, [isInactive, socket]);

  return isInactive;
};

export const useGetCurrentPage = () => {
  const { pathname } = useLocation();

  const [master, detail] = pathname.split('/').filter((x) => x);

  if (master?.toLowerCase() === 'chat') {
    return `/${master}`;
  }

  if (master?.toLowerCase() === 'help' && detail) {
    return `/${master}/${detail}`;
  }

  if (master?.toLowerCase() === 'customobject') {
    return detail;
  }

  return pathname;
};

export const useApp = () => useContext(AppContext);
