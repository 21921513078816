import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { LoadPanel } from 'devextreme-react';
import { msg } from '../../locale/loader';

export const Loading = ({ container = '#app-content' }) => {
  return <LoadPanel visible={true} container={container} />;
};

const AppLoading = () => {
  return (
    <AppLoadingWrapper>
      <img src={'assets/loadingLogo.svg'} {...imgProps} alt={msg('LOADING')} />
    </AppLoadingWrapper>
  );
};

export default AppLoading;

Loading.propTypes = {
  text: PropTypes.string,
};

const imgProps = {
  style: {
    maxWidth: '100%',
    maxHeight: '100%',
    height: '50px',
    margin: 'auto',
  },
};

const boxeffect = keyframes`
    0% {
    -webkit-transform: scale(1);
            transform: scale(1);
        }
    100% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    }
`;

const AppLoadingWrapper = styled.div`
  position: absolute;
  display: grid;
  width: 83px;
  height: 78px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  background: #00000014;
  border-radius: 5px;
  animation: ${boxeffect} 500ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.5s
    infinite alternate;
`;
