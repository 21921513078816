import { DOC_MODEL } from '../constants/api';

export const formatData = ({ dataSource, documentType = 'default' }) => {
  if (!dataSource) return [];

  const FORMATTERS = {
    default: (doc) => ({
      ...doc,
      label: doc.name,
      value: doc._id,
    }),
    setting: (doc) => ({
      label: doc.name,
      value: doc._id,
      name: doc?.name,
      deleted: doc.deleted,
      _id: doc?._id,
    }),
    [DOC_MODEL.EMAIL_TEMPLATE]: (doc) => ({
      ...doc,
      value: doc._id,
      company: doc.company === null ? 'all' : doc.company,
    }),
    [DOC_MODEL.USER]: (doc) => ({
      ...doc,
      label: `${doc.name} ${doc.surname}`,
      value: doc._id,
    }),
    [DOC_MODEL.CUSTOM_OBJECT]: (doc) => ({
      ...doc,
      value: doc._id,
    }),
    [DOC_MODEL.INVOICE]: (doc) => ({
      ...doc,
      label: `${doc.invoiceNumber}`,
      value: doc._id,
    }),
    [DOC_MODEL.OFFER]: (doc) => ({
      ...doc,
      label: `${doc.offerNumber}`,
      value: doc._id,
    }),
    [DOC_MODEL.ORDER]: (doc) => ({
      ...doc,
      label: `${doc.orderNumber}`,
      value: doc._id,
    }),
    [DOC_MODEL.DELIVERY_NOTE]: (doc) => ({
      ...doc,
      label: `${doc.deliveryNoteNumber}`,
      value: doc._id,
    }),
    [DOC_MODEL.BUSINESS]: (doc) => ({
      ...doc,
      label: doc.name,
      value: doc._id,
    }),
    [DOC_MODEL.TAX_ACCOUNT]: (doc) => ({
      ...doc,
      value: doc._id,
    }),
    [DOC_MODEL.PROVIDER]: (doc) => ({
      ...doc,
      label: doc.name,
      value: doc._id,
    }),
    [DOC_MODEL.ACCOUNTING_PERIOD]: (doc) => ({
      ...doc,
      label: `${doc.name}`,
      value: doc._id,
    }),
    [DOC_MODEL.NATURE]: (doc) => ({
      label: `${doc.description}`,
      value: doc._id,
      ...doc,
    }),
    [DOC_MODEL.HOLIDAY]: (doc) => ({
      ...doc,
      label: doc.name,
      value: doc._id,
    }),
    [DOC_MODEL.ATTACHMENT]: (doc) => ({
      ...doc,
      label: doc.fileName,
      value: doc._id,
    }),
    [DOC_MODEL.PRODUCT]: (doc) => ({
      ...doc,
      label: doc.code,
      value: doc._id,
    }),
    [DOC_MODEL.PRICE]: (doc) => ({
      ...doc,
      label: doc.price || doc.discount,
      value: doc._id,
    }),
    [DOC_MODEL.DISCOUNT]: (doc) => ({
      ...doc,
      label: doc.discount,
      value: doc._id,
    }),
    [DOC_MODEL.FAMILY]: (doc) => ({
      ...doc,
      label: doc.description,
      value: doc._id,
    }),
    [DOC_MODEL.UNIT]: (doc) => ({
      label: doc.description,
      value: doc._id,
      ...doc,
    }),
    [DOC_MODEL.CONCEPT]: (doc) => ({
      label: doc.code,
      value: doc._id,
      ...doc,
    }),
    [DOC_MODEL.SIGNATURE]: (doc) => ({
      value: doc._id,
      label: doc.name,
      ...doc,
    }),
    [DOC_MODEL.SIGNATURE_LEVEL]: (doc) => ({
      value: doc._id,
      label: doc.name,
      ...doc,
    }),
    [`process${DOC_MODEL.INVOICE}`]: ({ data, document, fileName }) => {
      delete data.entities;
      return {
        ...data,
        document,
        fileName,
      };
    },
    [`process${DOC_MODEL.OFFER}`]: ({ data, document, fileName }) => {
      delete data.entities;
      return {
        ...data,
        document,
        fileName,
      };
    },
    [`process${DOC_MODEL.ORDER}`]: ({ data, document, fileName }) => {
      delete data.entities;
      return {
        ...data,
        document,
        fileName,
      };
    },
    [`process${DOC_MODEL.DELIVERY_NOTE}`]: ({ data, document, fileName }) => {
      delete data.entities;
      return {
        ...data,
        document,
        fileName,
      };
    },
  };

  const formatter = FORMATTERS[documentType] ?? FORMATTERS.default;

  if (Array.isArray(dataSource)) return dataSource.map(formatter);

  if (typeof dataSource === 'object') return formatter(dataSource);
};
