const LOCAL_API = window?._env?.LOCAL_API;
const API_URL = 'https://qwarkocr-api.kuarasoftware.es/';
// const API_URL = 'http://localhost:3005/';

export const BASE_URL = LOCAL_API || API_URL;

export const DOC_MODEL = {
  UNPROCESSED_DOCUMENT: 'unprocessedDocument',
  SEARCH_ALL: 'db/searchall',
  ESTABLISHMENT: 'establishment',
  ESTABLISHMENT_INFO: 'establishment/info',
  ESTABLISHMENT_SETTINGS: 'establishment/settings',
  PROFILE: 'user/profile',
  USER_INFO: 'user/userInfo',
  USER: 'user',
  INVOICE: 'invoice',
  INVOICE_TEMPLATE: 'invoicetemplate',
  DELIVERY_NOTE: 'deliverynote',
  ORDER: 'order',
  OFFER: 'offer',
  BUSINESS: 'business',
  CLIENT: 'business',
  NATURE: 'nature',
  COMPANY: 'company',
  CONCEPT: 'concept',
  BANK_ACCOUNT: 'bankaccount',
  PROVIDER: 'provider',
  ENTITY: 'entity',
  ACCOUNTING_PERIOD: 'accountingperiod',
  SIGNATURE: 'signature',
  SIGNATURE_LEVEL: 'signatureLevel',
  ATTACHMENT: 'attachment',
  PRODUCT: 'product',
  PRICE: 'price',
  DISCOUNT: 'discount',
  FAMILY: 'family',
  UNIT: 'unit',
  CUSTOM_OBJECT_TYPE: 'customObjectType',
  CUSTOM_OBJECT: 'customObject',
  TAX_ACCOUNT: 'taxAccount',
  BUSINESS_UNIT: 'businessUnit',
  ROUTE: 'route',
  HOLIDAY: 'userholidays',
  BANKS: 'afterbanks',
  EMAIL_TEMPLATE: 'emailtemplate',
  COLUMN_SETTINGS: 'columnsettings',
  CHAT: 'chat',
  MESSAGE: 'message',
  RECURRENT_BILLS: 'recurrentInvoice',
  COMPANY_INFO: 'company/info'
};
